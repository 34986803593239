/**
 * Syntax highlighting styles
 */
.highlight {
  background-color: #f8f8f8;
  @extend %vertical-rhythm;

  .highlighter-rouge & {
    background-color: #f8f8f8;
  }

  .cm    { color: #727262; font-style: italic } // Comment.Multiline
  .cp    { color: #727272; font-weight: bold } // Comment.Preproc
  .c1    { color: #727262; font-style: italic } // Comment.Single
  .cs    { color: #727272; font-weight: bold; font-style: italic } // Comment.Special
  .c, .cd { color: #727262; font-style: italic } // Comment, Comment.Doc
  .err   { color: #a61717; background-color: #e3d2d2 } // Error
  .gd    { color: #000; background-color: #fdd } // Generic.Deleted
  .ge    { color: #000; font-style: italic } // Generic.Emph
  .gr    { color: #a00 } // Generic.Error
  .gh    { color: #727272 } // Generic.Heading
  .gi    { color: #000; background-color: #dfd } // Generic.Inserted
  .go    { color: #727272 } // Generic.Output
  .gp    { color: #555 } // Generic.Prompt
  .gs    { font-weight: bold } // Generic.Strong
  .gu    { color: #727272 } // Generic.Subheading
  .gt    { color: #a00 } // Generic.Traceback
  .kc    { color: #000; font-weight: bold } // Keyword.Constant
  .kd    { color: #000; font-weight: bold } // Keyword.Declaration
  .kn    { color: #000; font-weight: bold } // Keyword.Namespace
  .kp    { color: #000; font-weight: bold } // Keyword.Pseudo
  .kr    { color: #000; font-weight: bold } // Keyword.Reserved
  .kt    { color: #458; font-weight: bold } // Keyword.Type
  .k, .kv { color: #000; font-weight: bold } // Keyword, Keyword.Variable
  .mf    { color: #007f7f } // Literal.Number.Float
  .mh    { color: #007f7f } // Literal.Number.Hex
  .il    { color: #007f7f } // Literal.Number.Integer.Long
  .mi    { color: #007f7f } // Literal.Number.Integer
  .mo    { color: #007f7f } // Literal.Number.Oct
  .m, .mb, .mx { color: #007f7f } // Literal.Number, Literal.Number.Bin, Literal.Number.Other
  .sb    { color: #d14 } // Literal.String.Backtick
  .sc    { color: #d14 } // Literal.String.Char
  .sd    { color: #d14 } // Literal.String.Doc
  .s2    { color: #d14 } // Literal.String.Double
  .se    { color: #d14 } // Literal.String.Escape
  .sh    { color: #d14 } // Literal.String.Heredoc
  .si    { color: #d14 } // Literal.String.Interpol
  .sx    { color: #d14 } // Literal.String.Other
  .sr    { color: #008522 } // Literal.String.Regex
  .s1    { color: #d14 } // Literal.String.Single
  .ss    { color: #990073 } // Literal.String.Symbol
  .s     { color: #d14 } // Literal.String
  .na    { color: #007f7f } // Name.Attribute
  .bp    { color: #727272 } // Name.Builtin.Pseudo
  .nb    { color: #007aa3 } // Name.Builtin
  .nc    { color: #458; font-weight: bold } // Name.Class
  .no    { color: #007f7f } // Name.Constant
  .nd    { color: #3c5d5d; font-weight: bold } // Name.Decorator
  .ni    { color: #800080 } // Name.Entity
  .ne    { color: #900; font-weight: bold } // Name.Exception
  .nf    { color: #900; font-weight: bold } // Name.Function
  .nl    { color: #900; font-weight: bold } // Name.Label
  .nn    { color: #555 } // Name.Namespace
  .nt    { color: #000080 } // Name.Tag
  .vc    { color: #007f7f } // Name.Variable.Class
  .vg    { color: #007f7f } // Name.Variable.Global
  .vi    { color: #007f7f } // Name.Variable.Instance
  .nv    { color: #007f7f } // Name.Variable
  .ow    { color: #000; font-weight: bold } // Operator.Word
  .o     { color: #000; font-weight: bold } // Operator
  .w     { color: #727272 } // Text.Whitespace
}
