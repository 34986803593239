@import "whiteglass", "video.scss";

.post-title {
  font-size: 24px;
  letter-spacing: -1px;
  line-height: 1;
}

@media screen and (max-width: 800px) {
  .post-title {
    font-size: 24px;
  }
}

.site-title {
  font-size: 32px;
  font-weight: 300;
  line-height: 56px;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;
  color: #424242;
  &:visited {
    color: #424242;
  }
}